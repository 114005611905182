/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseService from "../../../services/CourseService";
import BackendUrls from "../../../utils/BackendUrls";

function BannerSection({ data }) {
  const [sessionsList, setSessionsList] = useState([]);
  const [currentSession, setCurrentSession] = useState();
  const [isLoading, setIsLoading] = useState({
    getAllSessions: { value: false },
  });
  const sliderData = [
    {
      title: "Ready to Join  <br/> Our Next Class?",
      subTitle: "This session will start on April 20, 2024.",
      textActionButton: "Join our Free MasterClass",
      urlActionButton: "/sessions/next",
      image: { url: "assets/images/bg/bg-image-39.png", description: "image" },
    },
    {
      title: "Ready to Join  <br/> Our Next Class?",
      subTitle: "This session will start on April 20, 2024.",
      textActionButton: "Join our Free MasterClass",
      urlActionButton: "/sessions/next",
      image: { url: "assets/images/bg/bg-image-40.png", description: "image" },
    },
  ];

  const getAllSessions = async () => {
    setIsLoading((prevState) => ({
      ...prevState,
      getAllSessions: { value: true },
    }));

    const response = await CourseService.getAllSessions();

    if (response.status === "success") {
      setSessionsList(response.data.result);
      setupCurrentSession(response.data.result);
      console.log("Session List", response.data);
    } else {
      // TODO: Manage Exception Here
    }

    setIsLoading((prevState) => ({
      ...prevState,
      getAllSessions: { value: false },
    }));
  };

  const setupCurrentSession = async (_sessionsList) => {
    if (_sessionsList.length !== 0) {
      setCurrentSession(_sessionsList[0]);
    } else setCurrentSession(null);
  };

  useEffect(() => {
    if (data) {
      getAllSessions();
    }
  }, [data]);

  return data && data.length === 1 && data[0].type === "Html" ? (
    <>
      <div
        className="hero-banner hero-style-1"
        dangerouslySetInnerHTML={{
          __html: data ? data[0]?.content : "",
        }}
      ></div>
    </>
  ) : data &&
    data.length >= 1 &&
    data.every((slide) => slide.type === "Image") ? (
    <>
      <div className="hero-banner hero-style-12 bg-image photography-banner">
        <div className="swiper photography-activator">
          <div className="swiper-wrapper">
            {data.map((slider, index) => (
              <div className="swiper-slide" key={index}>
                <img
                  data-transform-origin="center center"
                  // data-src={`${BackendUrls.lmsBaseURL}${slider.content}`}
                  src={`${slider.content}`}
                  className="swiper-lazy"
                  alt={slider.content}
                />
                {/* <div className="thumbnail-bg-content">
                    <div className="container edublink-animated-shape">
                      <div className="row">
                        <div className="col-7">
                          <div className="banner-content">
                            <h1
                              className="title"
                              data-sal-delay="100"
                              data-sal="slide-up"
                              data-sal-duration="1000"
                            >
                              {" "}
                              {slider.title}{" "}
                            </h1>
                            <p
                              data-sal-delay="200"
                              data-sal="slide-up"
                              data-sal-duration="1000"
                            >
                              {" "}
                              {slider.subTitle}{" "}
                            </p>
                            <div
                              className="banner-btn"
                              data-sal-delay="400"
                              data-sal="slide-up"
                              data-sal-duration="1000"
                            >
                              <a
                                href={slider.urlActionButton}
                                className="edu-btn"
                              >
                                {slider.textActionButton}{" "}
                                <i className="icon-4"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
            ))}
          </div>
          <div className="swiper-slide-controls slide-prev">
            <i className="icon-west"></i>
          </div>
          <div className="swiper-slide-controls slide-next">
            <i className="icon-east"></i>
          </div>

          <div className="pagination-box-wrapper">
            <div className="pagination-box-wrap">
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="hero-banner hero-style-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-content">
                <h2
                  className="title"
                  data-sal-delay="100"
                  data-sal="slide-up"
                  data-sal-duration="1000"
                >
                  Linux, AWS, and Devops training{" "}
                </h2>
                <br />
                <h1
                  className="title"
                  data-sal-delay="100"
                  data-sal="slide-up"
                  data-sal-duration="1000"
                >
                  <span className="color-secondary">
                    Ready to Join Our Next Class?
                  </span>
                </h1>
                <p
                  data-sal-delay="200"
                  data-sal="slide-up"
                  data-sal-duration="1000"
                >
                  This session will start on April 20, 2024.{" "}
                </p>
                <div
                  className="banner-btn"
                  data-sal-delay="400"
                  data-sal="slide-up"
                  data-sal-duration="1000"
                >
                  {currentSession ? (
                    <Link
                      to={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`}
                      className="edu-btn"
                    >
                      Join Our Free Masterclass <i className="icon-4"></i>
                    </Link>
                  ) : null}
                </div>
                <ul className="shape-group">
                  <li
                    className="shape-1 scene"
                    data-sal-delay="1000"
                    data-sal="fade"
                    data-sal-duration="1000"
                  >
                    <img
                      data-depth="2"
                      src="/assets/images/about/shape-13.png"
                      alt="Shape"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-thumbnail">
                <div
                  className="thumbnail"
                  data-sal-delay="500"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                >
                  <img src="/assets/images/banner/girl-1.webp" alt="Girl Img" />
                </div>

                {/* <InstructorInfo/> */}

                <ul className="shape-group">
                  <li
                    className="shape-1"
                    data-sal-delay="1000"
                    data-sal="fade"
                    data-sal-duration="1000"
                  >
                    <img
                      data-depth="1.5"
                      src="/assets/images/about/shape-15.png"
                      alt="Shape"
                    />
                  </li>
                  <li
                    className="shape-2 scene"
                    data-sal-delay="1000"
                    data-sal="fade"
                    data-sal-duration="1000"
                  >
                    <img
                      data-depth="-1.5"
                      src="/assets/images/about/shape-16.png"
                      alt="Shape"
                    />
                  </li>
                  <li
                    className="shape-3 scene"
                    data-sal-delay="1000"
                    data-sal="fade"
                    data-sal-duration="1000"
                  >
                    {/* <span data-depth="3" className="circle-shape"></span>*/}
                    <img
                      data-depth="1.6"
                      src="/assets/images/counterup/shape-08.png"
                      alt="Shape"
                    />
                  </li>

                  <li
                    className="shape-5 scene"
                    data-sal-delay="1000"
                    data-sal="fade"
                    data-sal-duration="1000"
                  >
                    <img
                      data-depth="1.5"
                      src="/assets/images/about/shape-13.png"
                      alt="Shape"
                    />
                  </li>
                  <li
                    className="shape-6 scene"
                    data-sal-delay="1000"
                    data-sal="fade"
                    data-sal-duration="1000"
                  >
                    <img
                      data-depth="-2"
                      src="/assets/images/about/shape-18.png"
                      alt="Shape"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-7">
          <img src="/assets/images/about/h-1-shape-01.png" alt="Shape" />
        </div>
      </div>
    </>
  );
}

export default BannerSection
