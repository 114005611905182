/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSignOutAlt, faTachometer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { getUser, logout } from "../../services/Auth";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = (e) => {
    e.preventDefault(); // Empêche le rafraîchissement de la page

    const paymentPlaform = process.env.REACT_APP_STOREFRONT;
    const parsedUrl = new URL(paymentPlaform);
    const baseUrl = parsedUrl.origin;

    logout(); // Appelle la fonction de déconnexion

    const redirectUrl = `${baseUrl}/logout-url`;
    window.location.href = redirectUrl;
  };

  return (
    <div className="dropdown">
      <ul class="header-action" role="button" onClick={toggleDropdown}>
        <li
          class=""
          style={{
            marginRight: "0",
          }}
        >
          <h6
            className="heading-title"
            style={{
              margin: "0",
            }}
          >
            {`${getUser().firstName} ${getUser().lastName}`} 🖖
          </h6>
        </li>
        <li class="" style={{ height: "47px", marginLeft: "10px" }}>
          <a className="dropdown-toggle-profile">
            <img
              src="/w/assets/images/user/avatar-2.jpg"
              alt="user img"
              className="rounded-circle"
              width="40"
              height="40"
              style={{ pointerEvents: "none" }}
            />
          </a>
        </li>
      </ul>
      {isOpen && (
        <ul className="dropdown-menu">
          <li className="userprofil">
            <h5>Profil</h5>
            <br />
            <div className="man">
              <img
                src="/w/assets/images/user/avatar-2.jpg"
                alt="user img"
                className="rounded-circle"
                width="40"
                height="40"
              />
              <div className="mane">
                <h6 className="mb-1">
                  {`${getUser().firstName} ${getUser().lastName}`} 🖖
                </h6>
                <span className="manee">{getUser().username}</span>
              </div>
            </div>
          </li>
          {/* <li className="dropdown-item">
            <a href="/"><FontAwesomeIcon icon={faCog} size="sm" style={{ marginRight: '5px' }} />Setting</a>
          </li> */}
          <li className="dropdown-item">
            <a href="/w">
              <FontAwesomeIcon
                icon={faTachometer}
                size="sm"
                style={{ marginRight: "5px" }}
              />{" "}
              User dashboard{" "}
            </a>
          </li>
          <li className="dropdown-item" onClick={handleLogout}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="sm"
              style={{ marginRight: "5px" }}
            />
            Logout
          </li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;