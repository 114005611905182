const routesPath = {
    home: '/',
    nextSession: '/sessions/next/:session_slug',
    courses: '/courses',
    courseDetails: '/courses/details/marche-2024',
    sessionsDetails: '/sessions/details/ansible-course-2024',
    interviewquestions: '/interview/questions',
    ComingSoon: 'ComingSoon',
    Support: '/submit/ticket',
    contactUs: '/contact_us',
    CompaniesStudentsWorked: '/companies/students/worked',
    error404: '/error404',
    faq: '/faq',
    testimonials: '/testimonials',
    feedback: '/feedback',
    termsOfUse:'/terms-of-use'
}

export default routesPath