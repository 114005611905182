import { CALL_API } from '../utils/HTTP';
import backendUrls from '../utils/BackendUrls';

const HomePageService = {
  getBannerInfo: async function () {
    const url = backendUrls.HOMEPAGE.BANNER_INFO;
    const secure = false,
      cache = false;
    return await CALL_API("GET", url, null, secure, cache);
  },
};

export default HomePageService;