import backendUrls from "../utils/BackendUrls";
import { CALL_API } from "../utils/HTTP";

const CourseService = {
  getAllSimplesCourses: async function (param = null) {
    const url = backendUrls.COURSE.ALL + (param ? param : "");
    const secure = false,
      cache = false;
    return await CALL_API("GET", url, null, secure, cache);
  },
  getAllSessions: async function (param = null) {
    const url = backendUrls.SESSION.ALL + (param ? param : "");
    const secure = false,
      cache = false;
    return await CALL_API("GET", url, null, secure, cache);
  },
  getDetails: async function (id) {
    const url = backendUrls.COURSE.DETAILS + `/${id}`;
    const secure = false,
      cache = false;
    return await CALL_API("GET", url, null, secure, cache);
  },
  getContent: async function (id) {
    const url = backendUrls.COURSE_SESSION.DETAILS + `/${id}`;
    const secure = false,
      cache = false;
    return await CALL_API("GET", url, null, secure, cache);
  },
};

export default CourseService;
