import React from "react";
import BannerCustom from "./../../components/pages/Next-session/IndividualPart/BannerCustom";
import CourseDetails from "./../../components/pages/Next-session/IndividualPart/CourseDetails/CourseDetails";
import MainSingleCource from "./../../components/pages/Next-session/IndividualPart/MoreCourse/MainSingleCource";

function SessionDetails() {
  return (
    <div>
      <BannerCustom />
      <CourseDetails />
      <MainSingleCource />
    </div>
  );
}

export default SessionDetails;
