import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLastBlock } from "../../utils/helpers";
import BannerCustom from "./../../components/pages/Next-session/IndividualPart/BannerCustom";
import CourseDetails from "./../../components/pages/Next-session/IndividualPart/CourseDetails/CourseDetails";
import MainSingleCource from "./../../components/pages/Next-session/IndividualPart/MoreCourse/MainSingleCource";
import CourseService from "./../../services/CourseService";

function NextSession() {
  const { session_slug } = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [sessionContentData, setSessionContentData] = useState([]);
  const [isLoading, setIsLoading] = useState({
    getAllCourses: { value: false },
    getSessionContent: { value: false },
  });

  const getSessionDetails = async () => {
    setIsLoading((prevState) => ({
      ...prevState,
      getAllCourses: { value: true },
    }));

    const sessionID = getLastBlock(session_slug);
    if (sessionID) {
      const response = await CourseService.getDetails(sessionID);

      if (response.status === "success") {
        setSessionData(response.data);
        console.log("data NextSession", response.data);
      } else {
        // TODO: Manage Exception Here
      }
    }
    setIsLoading((prevState) => ({
      ...prevState,
      getAllCourses: { value: false },
    }));
  };
  
  const getSessionContent = async () => {
    setIsLoading((prevState) => ({
      ...prevState,
      getSessionContent: { value: true },
    }));

    const sessionID = getLastBlock(session_slug);
    if (sessionID) {
      const response = await CourseService.getContent(sessionID);

      if (response.status === "success") {
        setSessionContentData(response.data.modules);
        console.log("Data NextSession Content", response.data);
      } else {
        // TODO: Manage Exception Here
      }
    }
    setIsLoading((prevState) => ({
      ...prevState,
      getSessionContent: { value: false },
    }));
  };

  useEffect(() => {
    getSessionDetails();
    getSessionContent();
  }, []);

  return (
    <div>
      <BannerCustom data={sessionData} />
      <CourseDetails
        data={sessionData}
        sessionContentData={sessionContentData}
      />
      {/* <MainSingleCource /> */}
    </div>
  );
}

export default NextSession;
